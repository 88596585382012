<template>
  <el-form :disabled="false" label-width="120px" size="mini">
    <div @mousemove="showSearch">
      <el-table :data="requForm.smpl_list" border @selection-change="handleSelectionChange" @cell-mouse-enter="slideCopy">
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="60" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="接收状态" width="120" align="center" prop="sample_status">
          <!--          接收状态 0未接收 1已接收 2已退回 3取消打样接收-->
          <template slot-scope="scope">
            <el-tag v-if="scope.row.requ_sample_status === 0" type="info">待接收</el-tag>
            <el-tag v-else-if="scope.row.requ_sample_status === 1" type="success">已接收</el-tag>
            <el-tag v-else-if="scope.row.requ_sample_status === 2" type="danger">已退单</el-tag>
            <el-tag v-else-if="scope.row.requ_sample_status === 3" type="success">已接收</el-tag>
            <el-tag v-else type="primary">暂无</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="打样状态" width="120" align="center" prop="sample_status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sample_status === 0" type="success">正常打样</el-tag>
            <el-tag v-else-if="scope.row.sample_status === 1" type="danger">取消打样</el-tag>
            <el-tag v-else type="primary">暂无</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200" label="老货号">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.old_prod_cust_no'">
              <el-input disabled v-model="requForm.smpl_list[scope.$index].old_prod_cust_no" placeholder="暂无老货号"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="vg_deep_red">样品编号</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_no'" :rules="[{ required: true }]">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].smpl_no ? requForm.smpl_list[scope.$index].smpl_no + '' : ''"
                placement="top-start"
              >
                <el-input
                  disabled
                  @change="smplTypeChange(scope)"
                  v-model="requForm.smpl_list[scope.$index].smpl_no"
                  @input="requForm.smpl_list[scope.$index].smpl_no = helper.keepEngNum2(requForm.smpl_list[scope.$index].smpl_no)"
                  maxlength="10"
                  show-word-limit
                  placeholder="暂无样品编号"
                >
                  <!-- [{validator: validatePass,trigger:['blur','focus'],required:true}] -->
                </el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="更改号" width="150">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_suffix'">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].smpl_suffix ? requForm.smpl_list[scope.$index].smpl_suffix + '' : ''"
                placement="top-start"
              >
                <el-input
                  disabled
                  v-model="requForm.smpl_list[scope.$index].smpl_suffix"
                  @input="requForm.smpl_list[scope.$index].smpl_suffix = helper.keepTNum(requForm.smpl_list[scope.$index].smpl_suffix)"
                  maxlength="2"
                  show-word-limit
                  placeholder="暂无更改号"
                ></el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="版本号" width="100">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.version_number'">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].version_number ? requForm.smpl_list[scope.$index].version_number + '' : ''"
                placement="top-start"
              >
                <el-input disabled v-model="requForm.smpl_list[scope.$index].version_number" placeholder="暂无版本号"></el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="客户货号" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_cust_no'">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].smpl_cust_no ? requForm.smpl_list[scope.$index].smpl_cust_no + '' : ''"
                placement="top-start"
              >
                <el-input
                  :disabled="isShow"
                  v-model="requForm.smpl_list[scope.$index].smpl_cust_no"
                  @input="requForm.smpl_list[scope.$index].smpl_cust_no = helper.keepEngNum2(requForm.smpl_list[scope.$index].smpl_cust_no)"
                  maxlength="30"
                  show-word-limit
                  placeholder="暂无客户货号"
                ></el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="vg_deep_red">样品名称</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_name'" :rules="[{ required: true }]">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].smpl_name ? requForm.smpl_list[scope.$index].smpl_name + '' : ''"
                placement="top-start"
              >
                <el-input
                  disabled
                  v-model="requForm.smpl_list[scope.$index].smpl_name"
                  maxlength="30"
                  show-word-limit
                  placeholder="暂无样品名称"
                ></el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span>样品英文名称</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_name_en'">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].smpl_name_en ? requForm.smpl_list[scope.$index].smpl_name_en + '' : ''"
                placement="top-start"
              >
                <el-input
                  disabled
                  v-model="requForm.smpl_list[scope.$index].smpl_name_en"
                  maxlength="100"
                  show-word-limit
                  placeholder="暂无样品英文名称"
                ></el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column width="191" :resizable="false">
          <template slot="header">
            <span class="vg_deep_red">玩具类型</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.'+scope.$index+'.toys_type'" :rules="[{required:true}]">
              <el-select :disabled="Number(requForm.smpl_list[scope.$index].requ_id) !== 0"  filterable @change="toysTypeChange(scope.$index)" v-model="requForm.smpl_list[scope.$index].toys_type" placeholder="请选择玩具类型" clearable>
                <el-option
                      v-for="item in toysTypeList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column> -->
        <el-table-column label="样品系列名" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_theme'">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].smpl_theme ? requForm.smpl_list[scope.$index].smpl_theme + '' : ''"
                placement="top-start"
              >
                <el-input
                  disabled
                  v-model="requForm.smpl_list[scope.$index].smpl_theme"
                  @input="requForm.smpl_list[scope.$index].smpl_theme = helper.NotkeepChin(requForm.smpl_list[scope.$index].smpl_theme)"
                  maxlength="40"
                  show-word-limit
                  placeholder="暂无系列名"
                ></el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="部件名称" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.part_name'">
              <el-tooltip
                class="item"
                effect="light"
                :content="requForm.smpl_list[scope.$index].part_name ? requForm.smpl_list[scope.$index].part_name + '' : ''"
                placement="top-start"
              >
                <el-input
                  :disabled="isShow"
                  v-model="requForm.smpl_list[scope.$index].part_name"
                  maxlength="30"
                  show-word-limit
                  placeholder="暂无部件名称"
                ></el-input>
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="191" :resizable="false">
          <template slot="header">
            <span class="vg_deep_red">委托类型</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_quot'" :rules="[{ required: true }]">
              <el-select disabled filterable v-model="requForm.smpl_list[scope.$index].smpl_quot" placeholder="请选择委托类型" clearable>
                <el-option v-for="item in smplQuotList" :key="item.id" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="开版师" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_stff_name'">
              <el-select
                filterable
                v-model="requForm.smpl_list[scope.$index].smpl_stff_name"
                placeholder="请选择开版师"
                clearable
                :disabled="isShow"
              >
                <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="vg_deep_red">样品类型</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_type'" :rules="[{ required: true }]">
              <el-select
                disabled
                v-model="requForm.smpl_list[scope.$index].smpl_type"
                placeholder="请选择样品类型"
                clearable
                @change="smplTypeChange(scope)"
              >
                <el-option v-for="item in smplType" :key="item.id" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">打样部门</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.'+scope.$index+'.belo_dept_id'" :rules="[{required:true}]">
              <el-select v-model="requForm.smpl_list[scope.$index].belo_dept_id" placeholder="请选择打样部门" clearable>
                <el-option
                      v-for="item in beloType"
                      :key="item.param2"
                      :label="item.param1"
                      :value="item.param2">
                  </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column> -->
        <el-table-column label="样品间报价" show-word-limit width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_price'">
              <el-input
                :disabled="isShow"
                maxlength="11"
                v-model="requForm.smpl_list[scope.$index].smpl_price"
                @input="requForm.smpl_list[scope.$index].smpl_price = helper.keepTNum1(requForm.smpl_list[scope.$index].smpl_price)"
                @blur="
                  requForm.smpl_list[scope.$index].smpl_price = helper.calcPrice(requForm.smpl_list[scope.$index].smpl_price, 4, 1000000)
                "
                show-word-limit
                placeholder="暂无样品间报价"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="采购部核价" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_verify_price'">
              <el-input
                :disabled="isShow"
                v-model="requForm.smpl_list[scope.$index].smpl_verify_price"
                maxlength="11"
                @input="
                  requForm.smpl_list[scope.$index].smpl_verify_price = helper.keepTNum1(requForm.smpl_list[scope.$index].smpl_verify_price)
                "
                @blur="
                  requForm.smpl_list[scope.$index].smpl_verify_price = helper.calcPrice(
                    requForm.smpl_list[scope.$index].smpl_verify_price,
                    4,
                    1000000
                  )
                "
                show-word-limit
                placeholder="暂无采购部核价"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="vg_deep_red">样品数量</span>
          </template>
          <template slot-scope="scope">
            <el-form-item
              label-width="0"
              :prop="'smpl_list.' + scope.$index + '.smpl_num'"
              :rules="requForm.smpl_list[scope.$index].toys_type === 0 ? { required: true } : { required: false }"
            >
              <el-input
                disabled
                show-word-limit
                maxlength="9"
                v-model="requForm.smpl_list[scope.$index].smpl_num"
                @input="requForm.smpl_list[scope.$index].smpl_num = helper.keepTNum(requForm.smpl_list[scope.$index].smpl_num)"
                placeholder="暂无样品数量"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="客户样品数量" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_cust_num'">
              <el-input
                disabled
                show-word-limit
                maxlength="9"
                v-model="requForm.smpl_list[scope.$index].smpl_cust_num"
                @input="requForm.smpl_list[scope.$index].smpl_cust_num = helper.keepTNum(requForm.smpl_list[scope.$index].smpl_cust_num)"
                placeholder="暂无客户样品数量"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="打样接收人" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_receive'">
              <el-input
                disabled
                show-word-limit
                v-model="requForm.smpl_list[scope.$index].smpl_receive"
                placeholder="暂无打样接收人"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="实际交样时间" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_list.' + scope.$index + '.smpl_deil_time'">
              <el-date-picker
                v-model="requForm.smpl_list[scope.$index].smpl_deil_time"
                type="date"
                placeholder="请选择实际交样时间"
                :disabled="isShow"
              >
              </el-date-picker>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column v-if="isShow && requForm.status === 2" width="120" align="center">
          <template slot-scope="scope">
            <el-link type="primary" class="vg_cursor" @click="jumpPodrProd(scope.row)">查看</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-form>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@api/modules/smpl';
import { userAPI } from '@api/modules/user';
import { optnAPI } from '@api/modules/optn';

export default {
  name: 'SmplEditBomChild',
  props: {
    requForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      smplFlag: 0,
      validatePass: (rule, value, callback) => {
        let that = this;
        if (value) {
          if (that.smplFlag === 2) {
            callback(new Error(''));
          } else if (that.smplFlag === 0) {
            callback(true);
          }
        }
      },
      selectionsList: [],
      smplQuotList: [
        { id: 1, label: '打样并报价' },
        { id: 2, label: '试样报价' },
        { id: 3, label: '仅打样' },
        { id: 4, label: '估价' },
        { id: 5, label: '返工' },
        { id: 8, label: '找料' },
        { id: 9, label: '印刷' },
        { id: 10, label: '电绣' }
      ],
      smplQuotList2: [
        { id: 1, label: '估价' },
        { id: 2, label: '仅报价' },
        { id: 3, label: '找样料' },
        { id: 4, label: '找用品' },
        { id: 5, label: '电绣/裁片' },
        { id: 6, label: '打样并报价' },
        { id: 7, label: '做文件' },
        { id: 8, label: '仅打样' }
      ],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' },
        { id: 4, label: '报价' },
        { id: 5, label: '其他' }
      ],
      toysTypeList: [
        { id: 0, label: '毛绒' },
        { id: 1, label: '非毛绒' }
      ],
      selectFlag: false,
      beloType: [],
      stffType: [],
      keyDownFlag: false, //按键ctrl按下确认
      mouseFlag: false, //鼠标是否向下
      mouseNum: 0, //鼠标判断向下向上
      mouseFlagCopy: true //鼠标是否首次进入
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getContry();
      this.getStffType();
    },
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    // 判断单号是否存在
    getSmplList(smplNo, smplType, smplId) {
      let params = {};
      params.smpl_no = smplNo;
      params.smpl_type = smplType;
      if (smplId) {
        params.smpl_id = smplId;
      } else {
        params.smpl_id = null;
      }
      post(smplAPI.judgeSmplNo, params)
        .then(res => {
          if (res.data.code === 0) {
            this.smplFlag = 1;
          } else {
            this.smplFlag = 2;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 跳转样品工单
    jumpPodrProd(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/smpl_edit', { perm_id: 112, form_id: row.smpl_id });
    },
    // 玩具类型
    toysTypeChange(val) {
      if (this.requForm.smpl_list[val].toys_type === 1) {
        this.requForm.smpl_list[val].smpl_cust_no = null;
        this.requForm.smpl_list[val].smpl_suffix = null;
        this.requForm.smpl_list[val].part_name = null;
        this.requForm.smpl_list[val].smpl_stff_name = null;
        this.requForm.smpl_list[val].smpl_price = null;
        this.requForm.smpl_list[val].smpl_verify_price = null;
        this.requForm.smpl_list[val].smpl_num = null;
        this.requForm.smpl_list[val].smpl_deil_time = null;
      }
    },
    // 样品类型变化
    smplTypeChange(scope) {
      if (scope.row.smpl_type !== 2) {
        if (scope.row.smpl_no && scope.row.smpl_type) {
          this.selectFlag = true;
          this.getSmplList(scope.row.smpl_no, scope.row.smpl_type, scope.row.smpl_id);
          setTimeout(() => {
            if (this.smplFlag === 2) {
              let str = JSON.parse(JSON.stringify(scope.row.smpl_no));
              if (str.indexOf('-') !== -1) {
                let temp = str.split('-');
                temp[1] = Number(temp[1]) + 1;
                str = temp.join('-');
              } else {
                str = str + '-1';
              }
              scope.row.smpl_no = str;
              this.$message({ message: '样品编号不能重复,已自动递增更改号', type: 'error' });
            }
            this.selectFlag = false;
          }, 800);
        }
      }

      // if(scope.row.smpl_type !== 2){
      //   if(scope.row.smpl_no && scope.row.smpl_type){
      //     this.selectFlag = true;
      //     this.getSmplList(scope.row.smpl_no,scope.row.smpl_type,scope.row.smpl_id);
      //     setTimeout(() => {
      //       if(this.smplFlag === 2){
      //         this.$refs["smplInput"+scope.$index].focus();
      //         setTimeout(() => {
      //           this.$refs["smplInput"+scope.$index].blur();
      //           this.selectFlag = false;
      //           this.smplFlag = 1;
      //         }, 300);
      //       }else{
      //         this.selectFlag = false;
      //         this.smplFlag = 1;
      //           this.$refs["smplInput"+scope.$index].focus()
      //           setTimeout(() => {
      //             this.$refs["smplInput"+scope.$index].blur()
      //           }, 300);
      //       }
      //     }, 800);
      //   }
      // }else{
      //   this.smplFlag = 1
      //   setTimeout(() => {
      //     this.$refs["smplInput"+scope.$index].focus()
      //     setTimeout(() => {
      //       this.$refs["smplInput"+scope.$index].blur()
      //     }, 300);
      //   }, 800);
      // }
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange', this.selectionsList);
    },

    // 监听键盘
    // 监听键盘
    keyDown() {
      document.onkeydown = e => {
        //事件对象兼容
        let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        //左
        if (e1 && e1.keyCode == 17) {
          this.keyDownFlag = true;
          // 按下左箭头
        } else {
          this.keyDownFlag = false;
          this.mouseFlagCopy = true;
          this.mouseNum = 0;
        }
      };
      document.onkeyup = e => {
        this.keyDownFlag = false;
        this.mouseFlagCopy = true;
        this.mouseNum = 0;
      };
    },
    showSearch(e) {
      if (this.keyDownFlag) {
        if (this.mouseFlagCopy) {
          this.mouseFlagCopy = false;
          this.mouseNum = JSON.parse(JSON.stringify(e.pageY));
        } else {
          if (this.mouseNum > e.pageY) {
            this.mouseFlag = false;
            this.mouseNum = JSON.parse(JSON.stringify(e.pageY));
          } else {
            this.mouseFlag = true;
            this.mouseNum = JSON.parse(JSON.stringify(e.pageY));
          }
        }
      }
    },
    // 触发单元格时
    slideCopy(row, column, cell, event) {
      if (this.isShow) {
        return;
      }
      if (this.keyDownFlag) {
        if (column.width === 191) {
          if (this.mouseFlag) {
            if (row.key > 0) {
              this.$set(this.requForm.smpl_list[row.key], 'smpl_quot', this.requForm.smpl_list[row.key - 1].smpl_quot);
              // this.requForm.smpl_list[row.key].smpl_quot = this.requForm.smpl_list[row.key-1].smpl_quot
            }
          }
        }
      }
    }
  },
  mounted() {
    this.keyDown();
  }
};
</script>

<style scoped>
/* .bac{
  border-radius: 5px;
  margin-top: 0px;
  border: rgb(255, 0, 0) 1px solid;
} */
</style>
